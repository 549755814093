
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import services from "../../../services/generic/services";
import CSMasterpage from "../../../components/CSMasterpage";
import notificationsHelper from "../../../utils/notificationsHelper";
import { useNotificationsContext, Text } from "tridonic-web-ui";

import { loginRequest } from "../../../config";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

export default function ValidateInvitePage() {
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const { inviteId, companyId } = useParams()
    const [isLoading, setIsLoading] = useState(false);
    const { addNotification } = useNotificationsContext()
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        async function fetchValidateInvite() {
            setIsLoading(true)
            let response = await services.validateInvite({
                companyId: companyId,
                inviteId: inviteId
            });
            
            if (response.success && response?.data == true) {
                if(!isAuthenticated && inProgress === InteractionStatus.None){
                    instance.loginRedirect(loginRequest);
                }
                history.push({ pathname: `/invites/acceptvalid/${inviteId}/${companyId}` })
            } else {
                switch (response?.data?.message) {
                    case 'Invite has expired.':
                        setErrorTitle('ValidateInvite.Error.Expired.Title');
                        setErrorMessage('ValidateInvite.Error.Expired.Message');
                        break;
                    case 'Invite has been cancelled.':
                        setErrorTitle('ValidateInvite.Error.Cancelled.Title');
                        setErrorMessage('ValidateInvite.Error.Cancelled.Message');
                        break;
                    case 'Invite has already been accepted.':
                        setErrorTitle('ValidateInvite.Error.Accepted.Title');
                        setErrorMessage('ValidateInvite.Error.Accepted.Message');
                        break;
                    case 'Invite not found.':
                        setErrorTitle('ValidateInvite.Error.NotFound.Title');
                        setErrorMessage('ValidateInvite.Error.NotFound.Message');
                        break;
                    default:
                        // Set default error title and message
                        setErrorTitle('Common.Error.Messages.DefaultError');
                        setErrorMessage('Common.Error.Messages.DefaultErrorDesc');
                        break;
                }
            }
            setIsLoading(false)
        }
        fetchValidateInvite()

    }, [])

    function handleWithLang(lng) {
        i18n.changeLanguage(lng);
    };

    function handleUserLanguageChange(lng) {
        setSessionLocaleByUser({ locale: lng, userId: user?.id});
        handleWithLang(lng);
    };


    const headerActions = [
        {
            icon: 'globe',
            options: [
                {
                    actionCallback: () => handleUserLanguageChange("en"),
                    disabled: false,
                    text: t('Header.Language.English'),
                    selected: i18n.language === 'en'
                },
                {
                    actionCallback: () => handleUserLanguageChange("de"),
                    disabled: false,
                    text: t('Header.Language.German'),
                    selected: i18n.language === 'de'
                },
            ]
        },

    ]

    return (
        <>
            <CSMasterpage
                headerActions={headerActions}
                criticalError={{title: t(errorTitle), message: t(errorMessage)}}
            >
            </CSMasterpage>
        </>
    )
}