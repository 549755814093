import React from 'react'
import {  useState } from "react";

import { ListView, HorizontalFlow } from "tridonic-web-ui";
//import HorizontalFlow from 'tridonic-web-ui/dist/Layout/HorizontalFlow';
import ListDataStyle from './ListDataStyle';

export default function ListData({ title, data, isLoading, callbackAction, callbackDeleteAction, type, t }) {

    function formatDate (dateString) {
        let date = new Date(Date.parse(dateString));
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getUTCDate()}`;
    }

    const [{ ItemTitle }, ] = useState(ListDataStyle())
    return (
        <>
            {isLoading || !data?.length ?
                null
                :
                <div style={{paddingTop: '30px'}}>
                <ListView
                    title={title}
                    active={true}
                    addNewItemPosition="none"
                    leadingAction={type === "Pending" ? item => { callbackAction(item.data.invite) } : ""}
                    leadingActionTitle={type === "Pending" ? "Resend" : ""}
                    leadingActionIcon={type === "Pending" ? "reboot" : ""}
                    trailingActionIcon={type === "Pending" ? "bin" : ""}
                    trailingAction={item => { callbackDeleteAction(item.data.invite)}}
                    itemDetailActive={false}
                    itemTitleWidthProfile={[
                        '100vw',
                        '100vw',
                        '30vw',
                        '30vw',
                        '30vw'
                    ]}
                    alternativeBackground={true}
                    listData={data.map((item, index) => (
                        {
                          invite: item.invite,  
                          active: true,
                          id: index,
                          title: item.title,
                          detailActionActive: false,
                          child:  <HorizontalFlow useBottomMargin={false} useTopMargin={false} width="100%"
                                        xsItems={1} mdItems={2} smItems={2} lgItems={2} xlItems={2}
                                        style={{flex: 1, gap: '0'}}
                                      >
                                      <ItemTitle active={false}>
                                          {item.description}
                                      </ItemTitle>
                                      <ItemTitle active={false}>
                                          {t('Account.Users.InviteValidity', { date: formatDate(item.invite.dateExpired) })}
                                      </ItemTitle>
                                  </HorizontalFlow>,

                        }))
                    }
                    loading={isLoading}
                    showLoadingWithActions={true}
                    dataTestId="listMemberInvites_listInvites"
                />
                </div>
            }
        </>
    )
}
