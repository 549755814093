import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CardView, useNotificationsContext } from "tridonic-web-ui";
import accessTokenProvider from '../../../services/generic/session'
import { useTranslation } from "react-i18next";
import services from "../../../services/generic/services";
import UserItem from "../../../components/UserItem";
import CSMasterpage from "../../../components/CSMasterpage";
import permition from "../../../services/generic/permition";

export default function HomePage() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [criticalError, setCriticalError] = useState('');
    const history = useHistory()
    const [companies, setCompanies] = useState([])

    const [user, setUser] = useState()
    const [DETAIL,] = useState("DETAIL");
    const [LEADING,] = useState("LEADING");
    const [TRAILLING,] = useState("TRAILLING");



    useEffect(() => {

        async function fetchUserData() {
            setIsLoading(true)
            const response = await services.getUser({
                tokenProvider: accessTokenProvider
            })
             debugger
            if (response.success) {
                const userData = response.data;
                setUser(userData);
                setCompanies(userData.companies.map((item, index) => ({
                    title: item.name,
                    rawData: {
                        item :item,
                         isAccountHolder: item.accountHolderId === userData.id, 
                         isAdmin: item?.roles?.some(item => "Admin" === item.name) } ,
                    leadingActionActive: item?.roles?.some(item => "Admin" === item.name) && item?.enabled,
                    trailingActionActive: item.accountHolderId === userData.id,
                    child: <UserItem
                        useMargins={true}
                        name={item.productName ? item.productName : t('PremiumAccounts.CardView.DefaultProductName')} />
                    // desc={t('PremiumAccounts.CardView.UserRole')}
                    // name={item.roles?.map(item => item.name !== "Other" ? t('PremiumAccounts.CardView.' + item.name) : item.name).join(', ')} />
                })))
            } else {
                if (response.data.isActionError) {
                    addNotification({
                        text: t(response.data.message),
                        type: 'critical'
                    })
                } else {
                    setCriticalError(response.data)
                }
            }

            setIsLoading(false)
        }

        fetchUserData()
    }, [])

    function goToNewAccountPage() {
        // history.push({ pathname: '/plans-auth' })
        //history.push({ pathname: '/new-account/plan/5', state:{name:"", address:"", countryCode:"", vat:"",phone:"", city: "", postalcode:"" } })
        history.push({ pathname: '/new-account/plan/5' })

    }
    
    async function goToTabPage(data, type) {
        if (type == DETAIL && (data.rawData.isAdmin || data.rawData.accountHolder)) {
            goToPremiumFeaturesPage(data)
        }else{
            goToStoragePage(data)
        }

        if (type == LEADING) {
                goToCompanyUserPage(data)
        }
        if (type == TRAILLING) {
            goToSettingsPage(data)
        }
    }

    function goToPremiumFeaturesPage(data) {
        history.push({ pathname: '/account/' + data.rawData?.item?.id + '/premiumFeatures'})
    }


    function goToCompanyUserPage(data) {
            history.push({ pathname: '/account/' + data.rawData?.item?.id + '/user' })
    }

    function goToSettingsPage(data) {
        history.push({ pathname: '/account/' + data.rawData?.item?.id + '/settings' })
    }

    function goToStoragePage(data) {
        history.push({ pathname: '/account/' + data.rawData?.item?.id + '/storage' })
    }
    return (
        <>
            <CSMasterpage
                criticalError={criticalError}
            >
                <CardView
                    addNewItemCallback={() => { goToNewAccountPage() }}
                    addNewItemText={t('PremiumAccounts.CardView.New')}
                    addNewItemPosition={companies.length > 0 ? "none" : "first"}
                    loading={isLoading}
                    cardsData={companies}
                    detailAction={item => { goToTabPage(item.data, DETAIL, user); }}
                   //trailingActionIcon = {item => item.isAdmin ? 'settings': null}
                    trailingActionIcon = 'settings'
                    trailingAction={item => { goToTabPage(item.data, TRAILLING, user); }}
                   //leadingActionTitle={item => (item.isAdmin || item.isAccountHolder )?  t('PremiumAccounts.CardView.Members'): null}
                    leadingActionTitle={  t('PremiumAccounts.CardView.Members')}
                    leadingAction={item => { goToTabPage(item.data, LEADING, user); }}
                ></CardView>
            </CSMasterpage>
        </>
    )
}
