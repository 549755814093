// Config object to be passed to Msal on creation
const b2cName = process.env.REACT_APP_AZURE_B2C_NAME
const clientId = process.env.REACT_APP_AZURE_CLIENTID 
export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: "https://" + b2cName + ".b2clogin.com/" + b2cName + ".onmicrosoft.com/b2c_1_susi",
        knownAuthorities: [b2cName + ".b2clogin.com"],
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: true
    },
    cache: {
        // The line above was commented because was causing some problems on loginRedirect,
        // in case this issue appears again comment the cacheLocation too
        cacheLocation: "localStorage",
        // storeAuthStateInCookie: true
    }
};

export const claims =
{
    signUpSignIn: "b2c_1_susi",
    forgotPassword: "b2c_1_reset",
    editProfile: "b2c_1_edit_profile"
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: [
        'openid', 
        'offline_access'
    ]
};

export const loginRequestwithScope = {
    scopes: [
        'openid', 
        'offline_access',       
        "https://" + b2cName + ".onmicrosoft.com/companionsuite/UserWebService"       
    ],
    redirectStartPage: "/"
};


export const loginRequestwithScope2 = {
    scopes: [
        'openid', 
        'offline_access',       
        "https://" + b2cName +".onmicrosoft.com/userwebservice/User.Read"
        
    ]
};

export const forgotPasswordRequest = {
    authority: "https://" + b2cName + ".b2clogin.com/" + b2cName + ".onmicrosoft.com/b2c_1_reset"
}

export const editProfile = {
    authority: "https://" + b2cName + ".b2clogin.com/" + b2cName + ".onmicrosoft.com/b2c_1_edit_profile"

}
export const notificationConfig = {
    success: {
        duration: 3,
        allowClose: false
    }
}

export const logoutRedirectUri = `${process.env.REACT_APP_URL_CS}/#/devicegenerator`;